/*
export const setAuthState = (state, newState) => {
    state.authState = newState
};

*/

import {setReactiveStateValue} from "@/helperFunctions";
import Vue from "vue";
import entityMap from "@/entityMap";

export const reset = (state) => {
	state.loggedIn = false;
	state.user = false;
	state.name = '';
	state.apiKey = false;
	state.type = '';
	state.locale = false;
	state.userHauler = false;
	state.hasFetchedInitialData = false;
	state.userPermissions = {
		global: [],
		entities: {}
	};
	state.userPermissionGroups = {};
	state.viewingEntity = false;
	state.bookings = {};
	state.bookingSegments = {};
	state.cards = {};
	state.companies = {};
	state.countries = {};
	state.display = {};
	state.haulers = {};
	state.haulingAssignments = {};
	state.users = {};
	state.machines = {};
	state.media_objects = {};
	state.permissions = {};
	state.permissionGroups = {};
	state.logEntries = {};
	state.bookingSegmentReservations = {};
	state.invoices = {};
};

export const setLoggedIn = (state, loggedIn) => {
	state.loggedIn = loggedIn
};

export const setUser = (state, user) => {
	state.user = user
};

export const setName = (state, name) => {
	state.name = name
};

export const setApiKey = (state, apiKey) => {
	state.apiKey = apiKey
};

export const setPerformedInitialStateLoad = (state, hasPerformedInitialStateLoad) => {
	state.hasPerformedInitialStateLoad = hasPerformedInitialStateLoad
};

export const setHasFetchedInitialData = (state, hasFetchedInitialData) => {
	state.hasFetchedInitialData = hasFetchedInitialData
};

export const setUserPermissions = (state,permissions) => {
	if(typeof permissions.global !== 'undefined')
	{
		state.userPermissions.global = permissions.global;
	}
	
	if(typeof permissions.entities !== 'undefined')
	{
		Object.keys(permissions.entities).forEach((key) => {
			const permissionsValues = typeof permissions.entities[key].length === 'undefined' ? Object.values(permissions.entities[key]) : permissions.entities[key] ;
			setReactiveStateValue(state.userPermissions.entities,key,permissionsValues)
		});
	}
};

export const setUserPermissionGroups = (state,permissionGroups) => {
	state.userPermissionGroups = permissionGroups;
}

export const setViewingEntity = (state, viewingEntity) => {
	state.viewingEntity = viewingEntity
};

export const setLocale = (state, locale) => {
	state.locale = locale
};

export const setUserHauler = (state, hauler) => {
	state.userHauler = hauler
};

/***********************************************************************
     .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
     / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
     `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
                        BASIC API ENTITIES
 
 ************************************************************************/

export const updateCards = (state, cards) => {
	if(typeof cards['hydra:member'] !== 'undefined')
		cards = cards['hydra:member'];
	if(typeof cards.length === 'undefined')
		cards = [cards];
	
	cards.forEach((card) => {
		setReactiveStateValue(state.cards,card.id,card)
	});
};

export const deleteCard = (state, cardId) => {
	Vue.delete(state.cards, cardId);
	delete state.cards[cardId];
};


export const updateHaulingAssignmentStatus = (state,payload) => {
	if(typeof state.haulingAssignments[payload.id] !== 'undefined')
	{
		state.haulingAssignments[payload.id].status = payload.status;
	}
};

export const genericEntityDataPersist = (state, entities) => {
	if(typeof entities['hydra:member'] !== 'undefined')
		entities = entities['hydra:member'];
	if(!Array.isArray(entities))
		entities = [entities];

	entities.forEach((entity) => {
		const property = entityMap[entity['@type']].stateProperty;
		setReactiveStateValue(state[property],entity.id,entity)
	});
};