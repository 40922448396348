import {findGetParameter, getCookie, isJsonString, setCookie} from "../helperFunctions";
import {mapState,mapGetters} from "vuex";

export default {
	computed: {
		...mapState('concrete', {
			loggedIn: 'loggedIn',
			hasPerformedInitialStateLoad: 'hasPerformedInitialStateLoad',
			user: 'user',
			locale: 'locale'
		}),
		...mapGetters('concrete', {
			globalScreenPermissions: 'globalScreenPermissions',
			entityScreenPermissions: 'entityScreenPermissions'
		}),
	},
    mounted(){
		
        if(this.hasPerformedInitialStateLoad === false);
        {
        	this.init();
        }
    },
	methods: {
		init(){
			initUserState(this.$store);
			this.$store.commit('concrete/setPerformedInitialStateLoad', true);

			if(this.loggedIn){
				const promises = [
					this.$store.dispatch('concrete/populateUserMetaData',this.user),
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'@type':'Country'},parameters:{prPage: 9999}})
				];
				
				Promise.all(promises)
				.then(response => {
					this.$i18n.locale = this.locale;
					this.updateViewingEntityConditionals()
					this.$store.commit('concrete/setHasFetchedInitialData', true)
				})
				.catch(error => {
					console.log(error);
					this.$store.dispatch('concrete/logout')
					.then(() => {
						this.$router.push({name:'login',params:{lang: this.$i18n.locale}})
					},() => {
						this.$router.push({name:'login',params:{lang: this.$i18n.locale}})
					})
				})
			}
		},
		updateViewingEntityConditionals(){
			let viewingEntity = false;
			if(this.globalScreenPermissions.length === 0)
			{
				const entities = Object.keys(this.entityScreenPermissions);
				if(entities.length !== 0)
				{
					viewingEntity = entities[0];
				}
			}
			this.$store.commit('concrete/setViewingEntity',viewingEntity);
		}
	},
	watch:{
		hasPerformedInitialStateLoad(to,from){
			if(to === false){
				this.init();
			}
		}
	}
}

function initUserState(store)
{
	return new Promise((resolve, reject) =>
	{
		const authState = getCookie("authState");
		if (typeof authState !== 'undefined' && isJsonString(authState))
		{
			const parsed = JSON.parse(authState);
			store.commit('concrete/setUser', parsed.id);
			store.commit('concrete/setName', parsed.name);
			store.commit('concrete/setApiKey', parsed.apikey);
			store.commit('concrete/setLoggedIn', true);
		}
		else
		{
			store.commit('concrete/reset');
			resolve(true);
		}
	});
}
