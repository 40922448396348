export default {
	PILOT: {
		key: 'PILOT',
		text: 'permissionGroups.pilot'
	},
	DISTRICT_MANAGER: {
		key: 'DISTRICT_MANAGER',
		text: 'permissionGroups.district_manager'
	},
	COMPANY: {
		key: 'COMPANY',
		text: 'permissionGroups.company'
	},
	ENTREPRENEUR: {
		key: 'ENTREPRENEUR',
		text: 'permissionGroups.entrepreneur'
	},
	HAULER: {
		key: 'HAULER',
		text: 'permissionGroups.hauler'
	},
	PARTNER: {
		key: 'PARTNER',
		text: 'permissionGroups.partner'
	},
}
