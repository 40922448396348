export default {
	'Booking': {
		endpoint: 'bookings',
		stateProperty: 'bookings',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'BookingSegment': {
		endpoint: 'booking_segments',
		stateProperty: 'bookingSegments',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'BookingSegmentReservation': {
		endpoint: 'booking_segment_reservations',
		stateProperty: 'bookingSegmentReservations',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Card': {
		endpoint: 'cards',
		stateProperty: 'cards',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Company': {
		endpoint: 'companies',
		stateProperty: 'companies',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Country': {
		endpoint: 'countries',
		stateProperty: 'countries',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'District': {
		endpoint: 'districts',
		stateProperty: 'districts',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Hauler': {
		endpoint: 'haulers',
		stateProperty: 'haulers',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'HaulingAssignment': {
		endpoint: 'hauling_assignments',
		stateProperty: 'haulingAssignments',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Invoice': {
		endpoint: 'invoices',
		stateProperty: 'invoices',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'LogEntry': {
		endpoint: 'log_entries',
		stateProperty: 'logEntries',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Machine': {
		endpoint: 'machines',
		stateProperty: 'machines',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'MediaObject': {
		endpoint: 'media_objects',
		stateProperty: 'media_objects',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'http://schema.org/MediaObject':{
		endpoint: 'media_objects',
		stateProperty: 'media_objects',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Trailer': {
		endpoint: 'trailers',
		stateProperty: 'trailers',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'User': {
		endpoint: 'users',
		stateProperty: 'users',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'Permission': {
		endpoint: 'permissions',
		stateProperty: 'permissions',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	},
	'PermissionGroup': {
		endpoint: 'permission_groups',
		stateProperty: 'permissionGroups',
		defaultMutations: [
			{
				methods: ['get','post','put'],
				mutation: 'genericEntityDataPersist',
				parameter: 'response'
			}
		]
	}
}
