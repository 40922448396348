/*
export function someGetter (state) {
}
*/
import permissions from "@/permissions";
import entityMap from "@/entityMap";
import rules from "@/rules";
import {flattenRulesObject, normalizeRuleValue} from "@/helperFunctions";
import permissionGroups from "@/permissionGroups";
import {buildIRI, extractIdFromIri} from "../../helperFunctions";

export function hasGlobalSuperAdmin (state) {
	return (state.userPermissions.global.indexOf(permissions.META.SUPER_ADMIN) !== -1)
}

export function viewingEntityIRIParts(state){
	if(state.viewingEntity === false)
		return {};
	
	const parts = state.viewingEntity.split('/');
	let entityParts = {
		'@id': state.viewingEntity,
		entity: false,
		id: parts[3]
	};
	
	const keys = Object.keys(entityMap);
	for(let i = 0; i < keys.length; i++){
		if(entityMap[keys[i]].endpoint === parts[2])
		{
			entityParts.entity = keys[i];
			break;
		}
	}
	return entityParts;
}

export function haulerIriParts(state)
{
	if(state.userHauler === false)
		return false;
	
	const parts = state.userHauler.split('/');
	let entityParts = {
		'@id': state.userHauler,
		entity: false,
		id: parts[3]
	};
	
	const keys = Object.keys(entityMap);
	for(let i = 0; i < keys.length; i++){
		if(entityMap[keys[i]].endpoint === parts[2])
		{
			entityParts.entity = keys[i];
			break;
		}
	}
	return entityParts;
}

export function possibleViewingEntities(state,getters){
	let valid = [];
	if(getters.globalScreenPermissions.length !== 0){
		valid.push({
			value: false,
			name: 'Global'
		})
	}

	Object.keys(state.userPermissions.entities).forEach((iri) => {
		const id = extractIdFromIri(iri);
		valid.push({
			value: iri,
			name: 'District '+id
		})

	})

	return valid;

}

export function viewingEntityPermissionGroups(state){
	if(state.viewingEntity === false)
		return [];
	
	return (typeof state.userPermissionGroups[state.viewingEntity] === 'undefined') ? [] : state.userPermissionGroups[state.viewingEntity] ;
}

export function primaryViewingEntityRole(state){
	if(state.viewingEntity === false)
		return 'NONE';
	
	const groups = (typeof state.userPermissionGroups[state.viewingEntity] === 'undefined') ? [] : state.userPermissionGroups[state.viewingEntity] ;

	if(groups.indexOf(permissionGroups.COMPANY.key) !== -1)
		return permissionGroups.COMPANY.key;
	else if(groups.indexOf(permissionGroups.ENTREPRENEUR.key) !== -1)
		return permissionGroups.ENTREPRENEUR.key;
	else if(groups.indexOf(permissionGroups.DISTRICT_MANAGER.key) !== -1)
		return permissionGroups.DISTRICT_MANAGER.key;
	else if(groups.indexOf(permissionGroups.PILOT.key) !== -1)
		return permissionGroups.PILOT.key;
	
	return 'NONE'
}

export function viewUsersAsEmployees(state){
	if(state.viewingEntity === false)
		return false;
	const groups = (typeof state.userPermissionGroups[state.viewingEntity] === 'undefined') ? [] : state.userPermissionGroups[state.viewingEntity] ;
	return groups.indexOf(permissionGroups.COMPANY.key) !== -1 || groups.indexOf(permissionGroups.ENTREPRENEUR.key) !== -1;
}

export function globalScreenPermissions(state)
{
	let screenPermissions = [];
	const screens = Object.values(permissions.SCREEN);
	const hasGlobalSuper = hasGlobalSuperAdmin(state);
	screens.forEach((screen) => {
		if(hasGlobalSuper || state.userPermissions.global.indexOf(screen) !== -1 )
		{
			screenPermissions.push(screen);
		}
	});
	return screenPermissions;
}

export function entityScreenPermissions(state)
{
	let screenPermissions = {};
	const entities = Object.keys(state.userPermissions.entities);
	const screens = Object.values(permissions.SCREEN);
	const hasGlobalSuper = hasGlobalSuperAdmin(state);
	for(let i = 0; i < entities.length; i++)
	{
		const hasLocalSuper = state.userPermissions.entities[entities[i]].indexOf(permissions.META.SUPER_ADMIN) !== -1;
		screens.forEach((screen) => {
			if(hasGlobalSuper || hasLocalSuper || state.userPermissions.entities[entities[i]].indexOf(screen) !== -1 )
			{
				if(typeof screenPermissions[entities[i]] === 'undefined')
				{
					screenPermissions[entities[i]] = [];
				}
				screenPermissions[entities[i]].push(screen);
			}
		})
	}
	return screenPermissions;
}

export function possibleRules(state)
{
	return Object.keys(rules);
}

export function groupedRules(state)
{
	let groups = {};
	
	Object.keys(rules).forEach((rule) => {
		if(typeof groups[rules[rule].group] === 'undefined')
		{
			groups[rules[rule].group] = [];
		}
		groups[rules[rule].group].push(rule);
	});
	
	return groups;
}

export function normalizedDefaultRuleValues(state)
{
	let defaultValues = {};
	
	Object.keys(rules).forEach((rule) => {
		defaultValues[rule] = normalizeRuleValue(rule,rules[rule].default)
	});
	
	return defaultValues;
}
