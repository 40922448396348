<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
	import performInitialLoad from "@/mixin/performInitialLoad";
	import {mapState} from "vuex";

	export default {
		mixins: [performInitialLoad],
		computed: {
			...mapState('concrete',{
				locale: 'locale'
			})
		},
		watch: {
			'$route':{
				deep: true,
				handler: function(to){
					if(to.params.lang !== this.$i18n.locale)
						this.$i18n.locale = to.params.lang;
				}
			},
			'$i18n.locale'(to){
				if(this.locale !== to)
				{
					this.$store.commit('concrete/setLocale',to);
					let params = Object.assign({},this.$route.params);
					params.lang = to;
					const route = {name: this.$route.name, params: params};

					if(JSON.stringify({name: this.$route.name, params: this.$route.params}) !== JSON.stringify(route))
						this.$router.push(route)

				}
			}
		}
	}
</script>

<style lang="scss">
	.pre-formatted {
		white-space: pre;
	}
</style>
