import Vue from 'vue';
import Vuex from 'vuex';
import concrete from './concrete';

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		concrete,
	},
});

export default store
