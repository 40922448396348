export default {
	hasPerformedInitialStateLoad: false,
	hasFetchedInitialData: false,
	loggedIn: false,
	user: false,
	name: '',
	apiKey: false,
	userHauler: false,
	locale: false,
	userPermissions: {
		global: [],
		entities: {},
	},
	userPermissionGroups: {},
	viewingEntity: false,
	
	// API entities
	bookingSegmentReservations: {},
	bookingSegments: {},
	bookings: {},
	cards: {},
	companies: {},
	countries: {},
	districts: {},
	haulers: {},
	haulingAssignments: {},
	invoices: {},
	logEntries: {},
	machines: {},
	media_objects: {},
	permissionGroups: {},
	permissions: {},
	trailers: {},
	users: {},
};
