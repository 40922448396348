import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n'
import concreteApi from "@/plugins/ConcreteApi";
import vSelect from 'vue-select';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(concreteApi);
Vue.use(VueSweetalert2);

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

const moment = require('moment')
require('moment/locale/da')

Vue.use(require('vue-moment'), {
    moment
});

import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import VueGtm from "vue-gtm";
import {findGetParameter} from "@/helperFunctions";

Vue.use(VueGtm, {
		id:  process.env.VUE_APP_GTM,
		enabled: true,
		debug: false,
		loadScript: true,
		vueRouter: router,
	}
);

Vue.config.productionTip = false;

Vue.mixin({
	methods: {
		fireGenericError(){
			this.$swal.fire({
				icon: 'error',
				title: this.$t("terms.error"),
				text: this.$t("errors.anErrorOccurredTryAgain"),
				confirmButtonText: this.$t("terms.ok"),
				confirmButtonColor: "#495760"
			});
		}
	},
})
const validLocales = ['en','da','de'];
router.beforeEach((to, from, next) => {
	if (typeof to.params.lang === 'undefined' || validLocales.indexOf(to.params.lang) === -1)
	{
		let baseLocale = 'en';
		const locale = findGetParameter('locale');

		if(typeof locale === 'string' && validLocales.indexOf(locale) !== -1)
			baseLocale = locale;

		let path = to.name !== null ? to.path : ''
		if(typeof to.params.lang !== 'undefined')
			path = path.replace('/'+to.params.lang,'')
		next('/'+baseLocale+path);
	}
	else
	{
		next();
	}
});

window.vue = new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');



