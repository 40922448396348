export default {
	BOOKING: {
		CREATE:'BOOKING:CREATE',
		READ: 'BOOKING:READ',
		UPDATE:'BOOKING:UPDATE',
		DELETE:'BOOKING:DELETE',
		OWN: {
			READ: 'BOOKING:OWN:READ',
			UPDATE:'BOOKING:OWN:UPDATE',
			DELETE:'BOOKING:OWN:DELETE',
		},
		SEGMENT: {
			CREATE:'BOOKING:SEGMENT:CREATE',
			READ: 'BOOKING:SEGMENT:READ',
			PERFORM: 'BOOKING:SEGMENT:PERFORM',
			UPDATE:'BOOKING:SEGMENT:UPDATE',
			DELETE:'BOOKING:SEGMENT:DELETE'
		}
	},
	CARD: {
		CREATE: 'CARD:CREATE',
		READ: 'CARD:READ',
		UPDATE: 'CARD:UPDATE',
		DELETE: 'CARD:DELETE'
	},
	COMPANY: {
		CREATE: 'COMPANY:CREATE',
		READ: 'COMPANY:READ',
		UPDATE: 'COMPANY:UPDATE',
		DELETE: 'COMPANY:DELETE'
	},
	COUNTRY: {
		CREATE: 'COUNTRY:CREATE',
		READ: 'COUNTRY:READ',
		UPDATE: 'COUNTRY:UPDATE',
		DELETE: 'COUNTRY:DELETE',
	},
	DISTRICT: {
		CREATE: 'DISTRICT:CREATE',
		READ: 'DISTRICT:READ',
		UPDATE: 'DISTRICT:UPDATE',
		DELETE: 'DISTRICT:DELETE',
		DISABLED: {
			UPDATE: 'DISTRICT:DISABLED:UPDATE'
		}
	},
	INVOICE: {
		READ: 'INVOICE:READ',
	},
	MACHINE: {
		CREATE:'MACHINE:CREATE',
		READ: 'MACHINE:READ',
		UPDATE:'MACHINE:UPDATE',
		DELETE:'MACHINE:DELETE'
	},
	HAULER: {
		CREATE:'HAULER:CREATE',
		READ: 'HAULER:READ',
		UPDATE:'HAULER:UPDATE',
		DELETE:'HAULER:DELETE',
		PERFORM: 'HAULER:PERFORM'
	},
	HAULING_ASSIGNMENT: {
		CREATE:'HAULING_ASSIGNMENT:CREATE',
		READ: 'HAULING_ASSIGNMENT:READ',
		UPDATE:'HAULING_ASSIGNMENT:UPDATE',
		DELETE:'HAULING_ASSIGNMENT:DELETE'
	},
	META: {
		SUPER_ADMIN: 'META:SUPER_ADMIN',
	},
	SCREEN: {
		BOOKING: 'SCREEN:BOOKING',
		COMPANY: 'SCREEN:COMPANY',
		COUNTRY: 'SCREEN:COUNTRY',
		DISTRICT: 'SCREEN:DISTRICT',
		MACHINE: 'SCREEN:MACHINE',
		USER: 'SCREEN:USER',
		PERMISSION: 'SCREEN:PERMISSION',
		HAULER: 'SCREEN:HAULER',
		INVOICE: 'SCREEN:INVOICE',
		TRAILER: 'SCREEN:TRAILER'
	},
	TRAILER: {
		CREATE:'TRAILER:CREATE',
		READ: 'TRAILER:READ',
		UPDATE:'TRAILER:UPDATE',
		DELETE:'TRAILER:DELETE'
	},
	USER: {
		CREATE: 'USER:CREATE',
		READ: 'USER:READ',
		UPDATE: 'USER:UPDATE',
		DELETE: 'USER:DELETE',
		PERMISSION: {
			READ: 'USER:PERMISSION:READ',
			UPDATE: 'USER:PERMISSION:UPDATE',
		},
		MANAGE:{
			PILOT: 'USER:MANAGE:PILOT',
			DISTRICT_MANAGER: 'USER:MANAGE:DISTRICT_MANAGER',
			COMPANY: 'USER:MANAGE:COMPANY',
			ENTREPRENEUR: 'USER:MANAGE:ENTREPRENEUR'
		},
	},
	PERMISSION: {
		READ: 'PERMISSION:READ'
	},
	RULES: {
		READ: 'RULES:READ',
		UPDATE: 'RULES:UPDATE'
	}
}
