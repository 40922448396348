<template>
	<div class="home">
		home
	</div>
</template>

<script>
	import {findGetParameter} from "@/helperFunctions";

	export default {
		name: 'Home',
		beforeRouteEnter(to, from, next) {
			const validLocales = ['en','da','de'];
			let baseLocale = 'en';
			const locale = findGetParameter('locale');

			if(typeof locale === 'string' && validLocales.indexOf(locale) !== -1)
				baseLocale = locale;

			next({name:'admin',params: {lang: baseLocale}})
		}
	};
</script>
