import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';
import permissions from "@/permissions";

Vue.use(VueRouter);

const routes = [
	{
		path: '/:lang(da|en|de)',
		component: () => import(/* webpackChunkName: "plain" */ '../layouts/Plain'),
		children: [
			{
				path: '',
				name: 'home',
				component: Home
			},
			{
				path: 'login',
				name: 'login',
				component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
			},
			{
				path: 'account',
				component: () => import(/* webpackChunkName: "plain-with-sidebar" */ '../layouts/PlainWithSidebar'),
				children: [
					{
						path: 'validate-email/:token',
						name: 'validate-email',
						component: () => import(/* webpackChunkName: "validate-email" */ '../views/account/ValidateEmail')
					},
					{
						path: 'forgot-password',
						name: 'forgot-password',
						component: () => import(/* webpackChunkName: "forgot-password" */ '../views/account/ForgotPassword')
					},
					{
						path: 'token-password-change/:user/:token',
						name: 'token-password-change',
						component: () => import(/* webpackChunkName: "token-password-change" */ '../views/account/ResetPassword')
					},
					{
						path: 'anon-login/:user/:token',
						name: 'anon-login',
						component: () => import(/* webpackChunkName: "anon-login" */ '../views/account/AnonLogin')
					},
					{
						path: 'create-company',
						name: 'create-company',
						component: () => import(/* webpackChunkName: "create-company" */ '../views/account/CreateCompany.vue')
					},
				]
			},
			{
				path: 'admin',
				component: () => import(/* webpackChunkName: "admin" */ '../layouts/PermissionDependant'),
				children: [
					{
						path: '/',
						name: 'admin',
						component: () => import(/* webpackChunkName: "dashboard" */ '../views/admin/Dashboard.vue'),
						meta: {
							enableAuth: true,
						}
					},
					{
						path: 'reservation/:id/:action/:token',
						name: 'reservation-action',
						component: () => import(/* webpackChunkName: "reservation-action" */ '../views/account/ReservationAction.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.BOOKING,permissions.BOOKING.CREATE]
						}
					},

					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 BOOKING

					 ************************************************************************/
					{
						path: 'bookings/add',
						name: 'booking-add',
						component: () => import(/* webpackChunkName: "booking-addEdit" */ '../views/admin/booking/AddEditBooking.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.BOOKING,permissions.BOOKING.CREATE]
						}
					},
					{
						path: 'bookings/:id',
						name: 'booking-view',
						component: () => import(/* webpackChunkName: "booking-single" */ '../views/admin/booking/SingleBooking.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.BOOKING]
						}
					},
					{
						path: 'bookings/:id/edit',
						name: 'booking-edit',
						component: () => import(/* webpackChunkName: "booking-addEdit" */ '../views/admin/booking/AddEditBooking.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.BOOKING,permissions.BOOKING.UPDATE]
						}
					},


					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 BOOKING SEGMENTS

					 ************************************************************************/


					{
						path: 'bookingsegments/:id/edit',
						name: 'bookingSegment-edit',
						component: () => import(/* webpackChunkName: "booking-addEdit" */ '../views/admin/bookingSegments/EditBookingSegment.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.BOOKING,permissions.BOOKING.UPDATE]
						}
					},



					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 COMPANY

					 ************************************************************************/
					{
						path: 'companies/add',
						name: 'company-add',
						component: () => import(/* webpackChunkName: "company-addEdit" */ '../views/admin/company/AddEditCompany.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.COMPANY,permissions.COMPANY.CREATE]
						}
					},
					{
						path: 'companies/:id',
						name: 'company-view',
						component: () => import(/* webpackChunkName: "company-single" */ '../views/admin/company/SingleCompany.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.COMPANY]
						}
					},
					{
						path: 'companies/:id/edit',
						name: 'company-edit',
						component: () => import(/* webpackChunkName: "company-addEdit" */ '../views/admin/company/AddEditCompany.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.COMPANY,permissions.COMPANY.UPDATE]
						}
					},




					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 COUNTRY

					 ************************************************************************/
					{
						path: 'countries/add',
						name: 'country-add',
						component: () => import(/* webpackChunkName: "country-addEdit" */ '../views/admin/country/AddEditCountry.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.COUNTRY,permissions.COUNTRY.CREATE]
						}
					},
					{
						path: 'countries/:id',
						name: 'country-view',
						component: () => import(/* webpackChunkName: "country-single" */ '../views/admin/country/SingleCountry.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.COUNTRY]
						}
					},
					{
						path: 'countries/:id/edit',
						name: 'country-edit',
						component: () => import(/* webpackChunkName: "country-addEdit" */ '../views/admin/country/AddEditCountry.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.COUNTRY,permissions.COUNTRY.UPDATE]
						}
					},



					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 DISTRICT

					 ************************************************************************/
					{
						path: 'districts/add',
						name: 'district-add',
						component: () => import(/* webpackChunkName: "district-addEdit" */ '../views/admin/district/AddEditDistrict'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.DISTRICT,permissions.DISTRICT.CREATE]
						}
					},
					{
						path: 'districts/:id',
						name: 'district-view',
						component: () => import(/* webpackChunkName: "district-single" */ '../views/admin/district/SingleDistrict'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.DISTRICT]
						}
					},
					{
						path: 'districts/:id/edit',
						name: 'district-edit',
						component: () => import(/* webpackChunkName: "district-addEdit" */ '../views/admin/district/AddEditDistrict'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.DISTRICT,permissions.DISTRICT.UPDATE]
						}
					},






					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 HAULER

					 ************************************************************************/
					// @deprecated Hauling system is deprecated since version 2
					/*{
                        path: 'haulers/add',
                        name: 'hauler-add',
                        component: () => import(/!* webpackChunkName: "hauler-addEdit" *!/ '../views/admin/hauler/AddEditHauler.vue'),
                        meta: {
                            enableAuth: true,
                            permissions: [permissions.SCREEN.HAULER,permissions.HAULER.CREATE]
                        }
                    },
                    {
                        path: 'haulers/:id',
                        name: 'hauler-view',
                        component: () => import(/!* webpackChunkName: "hauler-single" *!/ '../views/admin/hauler/SingleHauler.vue'),
                        meta: {
                            enableAuth: true,
                            permissions: [permissions.SCREEN.HAULER]
                        }
                    },
                    {
                        path: 'haulers/:id/edit',
                        name: 'hauler-edit',
                        component: () => import(/!* webpackChunkName: "hauler-addEdit" *!/ '../views/admin/hauler/AddEditHauler.vue'),
                        meta: {
                            enableAuth: true,
                            permissions: [permissions.SCREEN.HAULER,permissions.HAULER.UPDATE]
                        }
                    },*/






					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 MACHINE

					 ************************************************************************/
					{
						path: 'machines/add',
						name: 'machine-add',
						component: () => import(/* webpackChunkName: "machine-addEdit" */ '../views/admin/machine/AddEditMachine.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.MACHINE,permissions.MACHINE.CREATE]
						}
					},
					{
						path: 'machines/:id',
						name: 'machine-view',
						component: () => import(/* webpackChunkName: "machine-single" */ '../views/admin/machine/SingleMachine.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.MACHINE]
						}
					},
					{
						path: 'machines/:id/edit',
						name: 'machine-edit',
						component: () => import(/* webpackChunkName: "machine-addEdit" */ '../views/admin/machine/AddEditMachine.vue'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.MACHINE,permissions.MACHINE.UPDATE]
						}
					},



					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 TRAILERS

					 ************************************************************************/
					{
						path: 'trailers/add',
						name: 'trailer-add',
						component: () => import(/* webpackChunkName: "trailer-addEdit" */ '../views/admin/trailer/AddEditTrailer'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.TRAILER,permissions.TRAILER.CREATE]
						}
					},
					{
						path: 'trailers/:id',
						name: 'trailer-view',
						component: () => import(/* webpackChunkName: "trailer-single" */ '../views/admin/trailer/SingleTrailer'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.TRAILER]
						}
					},
					{
						path: 'trailers/:id/edit',
						name: 'trailer-edit',
						component: () => import(/* webpackChunkName: "trailer-addEdit" */ '../views/admin/trailer/AddEditTrailer'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.TRAILER,permissions.TRAILER.UPDATE]
						}
					},



					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 USERS

					 ************************************************************************/
					{
						path: 'users/add',
						name: 'user-add',
						component: () => import(/* webpackChunkName: "user-addEdit" */ '../views/admin/user/AddEditUser'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.USER,permissions.USER.CREATE]
						}
					},
					{
						path: 'users/:id',
						name: 'user-view',
						component: () => import(/* webpackChunkName: "user-single" */ '../views/admin/user/SingleUser'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.USER]
						}
					},
					{
						path: 'users/:id/edit',
						name: 'user-edit',
						component: () => import(/* webpackChunkName: "user-addEdit" */ '../views/admin/user/AddEditUser'),
						meta: {
							enableAuth: true,
							permissions: [permissions.SCREEN.USER,permissions.USER.UPDATE]
						}
					},







					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 GENERIC

					 ************************************************************************/
					{
						path: ':entityType',
						name: 'entity-list',
						component: () => import(/* webpackChunkName: "entity-list" */ '../views/admin/EntityList'),
						meta: {
							enableAuth: true,
						}
					},
					{
						path: ':parentType/:id/:entityType',
						name: 'parented-entity-list',
						component: () => import(/* webpackChunkName: "entity-list" */ '../views/admin/EntityList'),
						meta: {
							enableAuth: true,
						}
					},

					/***********************************************************************
					 .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
					 / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
					 `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
					 PILOT CHECK IN OUT

					 ************************************************************************/
					{
						path: 'assignment/:id',
						name: 'pilot-check-in-out',
						component: () => import(/* webpackChunkName: "pilot-check-in-out" */ '../views/admin/PilotCheckInOut'),
						meta: {
							enableAuth: true,
						}
					},
					// @deprecated Hauling system is deprecated since version 2
					/*{
                        path: 'hauling-perform/:id',
                        name: 'hauling-assignment-perform',
                        component: () => import(/!* webpackChunkName: "hauling-assignment-perform" *!/ '../views/admin/HaulingAssignmentPerform'),
                        meta: {
                            enableAuth: true,
                        }
                    },*/
					{
						path: '*',
						component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
						meta: {
							enableAuth: true,
						}
					},
				]
			},
			{
				path: '*',
				component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
			},
		]
	},
	{
		path: '*',
		component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if(typeof to.meta !== 'undefined' && typeof to.meta.enableAuth !== 'undefined' && to.meta.enableAuth === true)
	{
		if(store.state.concrete.hasPerformedInitialStateLoad === false)
		{
			const interval = setInterval(() => {
				if(store.state.concrete.hasPerformedInitialStateLoad === true)
				{
					clearInterval(interval);
					handleRouteAuth(to,from,next)
				}
			},20);
		}
		else
		{
			handleRouteAuth(to,from,next)
		}
	}
	else
	{
		next();
	}
});

function handleRouteAuth(to,from,next){
	if(store.state.concrete.loggedIn === true)
	{
		next()
	}
	else
	{
		const lang = typeof to.params.lang !== 'undefined' ? to.params.lang : 'en'
		store.commit('concrete/reset');
		next('/'+lang+'/login');
	}
}

export default router;
