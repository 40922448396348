export default {
	'CURRENCY': {
		key: 'CURRENCY',
		type: 'string',
		default: 'EUR',
		group: 'currency'
	},
	'CURRENCY:POSITION': {
		key: 'CURRENCY:POSITION',
		type: 'string',
		default: 'left',
		group: 'currency'
	},
	'CURRENCY:THOUSANDS_SEPARATOR': {
		key: 'CURRENCY:THOUSANDS_SEPARATOR',
		type: 'string',
		default: '.',
		group: 'currency'
	},
	'CURRENCY:DECIMAL_SEPARATOR': {
		key: 'CURRENCY:DECIMAL_SEPARATOR',
		type: 'string',
		default: ',',
		group: 'currency'
	},
	'CURRENCY:DECIMAL_COUNT': {
		key: 'CURRENCY:DECIMAL_COUNT',
		type: 'numeric',
		default: 2,
		group: 'currency'
	},
	'BOOKING:SLOTS': {
		type: 'json',
		key: 'BOOKING:SLOTS',
		default: [
			{
				'start': 0,
				'end': 5,
				'priceMultiplier': 2
			},
			{
				'start': 7,
				'end': 15,
				'priceMultiplier': 1
			},
			{
				'start': 17,
				'end': 22,
				'priceMultiplier': 1.5
			}
		],
		group: 'booking'
	},
	'BOOKING:PRICE_PR_HOUR': {
		key: 'BOOKING:PRICE_PR_HOUR',
		type: 'numeric',
		default: 700,
		group: 'booking'
	},
	'BOOKING:MINIMUM_HOURS_BEFORE': {
		key: 'BOOKING:MINIMUM_HOURS_BEFORE',
		type: 'numeric',
		default: 12,
		group: 'booking'
	},
	'BOOKING:LATE_CANCEL_PENALTIES': {
		key: 'BOOKING:LATE_CANCEL_PENALTIES',
		type: 'json',
		default: [
			{
				'hoursBefore': 24,
				'penaltyPercent': 100
			},
			{
				'hoursBefore': 48,
				'penaltyPercent': 50
			}
		],
		group: 'booking'
	}
}
