import Vue from 'vue';
import entityMap from "@/entityMap";
import rules from "@/rules";

export function getCookie(name)
{
	const value = "; " + document.cookie;
	const parts = value.split("; " + name + "=");
	if (parts.length === 2)
	{
		return unescape(parts.pop().split(";").shift());
	}
}

export function setCookie(name, val, hours)
{
	let now = new Date();
	let time = now.getTime();
	time += hours * 3600 * 1000;
	now.setTime(time);
	
		document.cookie = name + '=' + val + '; expires=' + now.toUTCString() + ';path=/';
	}

	export function deleteCookie(name)
	{
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
	}

	export function isJsonString(str)
	{
		try
		{
		JSON.parse(str);
	} catch (e)
	{
		return false;
	}
	return true;
}

export function generateRandomString(length)
{
	const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const charactersLength = characters.length;
	let randomString = '';
	for (let i = 0; i < length; i++)
	{
		randomString += characters[Math.floor(Math.random() * charactersLength)];
	}
	return randomString;
}

export function setReactiveStateValue(storeObject, property, value)
{
	if (typeof storeObject[property] === 'undefined')
	{
		Vue.set(storeObject, property, value);
	}
	else
	{
		storeObject[property] = value;
	}
}


export function implodeObject(object, glue, separator)
{
	if (typeof glue === 'undefined')
		glue = '=';
	if (typeof separator === 'undefined')
		separator = ',';
	
	return Object.getOwnPropertyNames(object).map(function (k){
		return [k, object[k]].join(glue);
	}).join(separator);
}

export function buildIRI(entityId, entityType)
{
	return '/api/'+entityMap[entityType].endpoint+'/'+entityId;
}

export function extractIdFromIri(string)
{
	const parts = string.split('/');
	return parts[parts.length - 1];
}

export function normalizeRuleValue(rule,value)
{
	if(rules[rule].type === 'string')
	{
		return (typeof value !== 'string') ? value.toString().trim() : value.trim() ;
	}
	else if(rules[rule].type === 'numeric')
	{
		return typeof value !== 'number' ? Number(value) : value;
	}
	else if(rules[rule].type === 'json')
	{
		return (typeof value !== 'string') ? JSON.stringify(value) : value;
	}
}

export function simpleEmailValidation(email){
	const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	return re.test(String(email).toLowerCase());
}


export function hashCode(str) {
	var hash = 0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var color = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export function intToRGB(i){
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

export function validateGenericInput(map, value)
{
	if (map.required === true && value === '')
	{
		return 'mustBeDefined';
	}
	
	if (value !== '')
	{
		if (typeof map.type !== 'undefined')
		{
			if (map.type === 'email')
			{
				if (simpleEmailValidation(value) === false)
				{
					return 'notAnEmail';
				}
			}
			else if(map.type === 'expires')
			{
				const expiresRegEx = /^[0-9]{2}\/[0-9]{2}$/;
				const valid = expiresRegEx.test(value);
				if(valid)
				{
					const parts = value.split('/');
					if(parts[0] > 12)
					{
						return 'expiresMonthTooHigh';
					}
					else
					{
						const date = new Date(Number('20'+parts[1]),parts[0]-1);
						if(new Date() > date)
						{
							return 'expiresExpired';
						}
					}
				}
				else
				{
					return 'incorrectExpiresFormat';
				}
			}
		}
		
		if (typeof map.minLength !== 'undefined')
		{
			if (value.toString().length < map.minLength)
			{
				return 'tooShort';
			}
		}
	}
	
	return false;
}

export function findGetParameter(parameterName) {
	let result = null,
		tmp = [];
	location.search
	.substr(1)
	.split("&")
	.forEach(function (item) {
		tmp = item.split("=");
		if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
	});
	return result;
}

