/***********************************************************************
         .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
         / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
         `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
                            ACCOUNT / SECURITY
 
 ************************************************************************/
import {deleteCookie, extractIdFromIri, implodeObject, setCookie} from "@/helperFunctions";
import entityMap from "@/entityMap";
import i18n from "@/i18n";

export function login(context, values) {
	return new Promise((resolve, reject) => {
		const promise = this._vm.$concreteApi.post(undefined,'/login', values,true);
		promise.then(response => {
			setCookie('authState',JSON.stringify({
				name: response.name,
				apikey: response.apikey,
				id: response.id
			}),60 * 60 * 24 * 7);
			
			context.commit('reset');
			context.commit('setPerformedInitialStateLoad',false);
			
			resolve(response);
		})
		.catch(error => {
			reject(error)
		});
	});
}

export function logout(context){
	return new Promise((resolve, reject) => {
		deleteCookie('authState');
		context.commit('reset');
		context.commit('setPerformedInitialStateLoad',false);
		resolve(true);
	});
}



export function populateUserMetaData(context, userId) {
	return new Promise((resolve, reject) => {
		const promise = this._vm.$concreteApi.get(context.state.apiKey,'/users/'+userId+'/meta');
		promise.then(response => {
			context.commit('setLocale',response.locale);
			context.commit('setUserPermissions',response.permissions);
			context.commit('setUserPermissionGroups',response.permissionGroups);
			context.commit('setUserHauler',response.hauler);
			resolve(true);
		})
		.catch(error => {
			reject(error)
		});
	});
}




/***********************************************************************
         .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-.   .-.-
         / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \ \ / / \
         `-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'   `-`-'
                                GENERIC
 
 ************************************************************************/

/**
 * Builds and performs a generic request against the concrete elephant symfony api, based on a basic request structure.
 *
 * request structure is as follows:
 * {
 *     entity: {
 *         '@id': <string - optional> - The api IRI for a given entity, ie. "/api/countries/1".
 *         '@type': <string - required> - The entity type, must match a property in the entityMap. Matches the naming conventions of the API entities.
 *         id: <int - optional> - The raw entity ID, is only applied if "@id" isn't set.
 *     },
 *     method: <string - optional> - The method the request should perform, defaults to GET.
 *     parameters: <object - optional> - Additional GET parameters to apply to the url. The given object is applied 1-1 by a ?property=value logic. ie. {currency_equals: 'DKK'} results in ?currency_equals=DKK in the url.
 *     payload: <mixed - option> - The payload to send with the request, when it's a POST or PUT request.
 *     mutations: [ <array - optional> - And array of mutation objects that should be run after a successful request
 *         {
 *             methods: <array - optional> - An array of the methods upon which the mutation should be run
 *             mutation: <string - required> - The mutation to run
 *             parameter: <mixed - optional> - The parameter to feed the mutation, if any. "response" feeds to request response.
 *         }
 *     ],
 *     skipMutations: <bool - optional> - Whether the request should perform mutations on a successful request. defaults to false.
 * }
 *
 *
 * @param context
 * @param request
 * @returns {Promise<unknown>}
 */
export function genericEntityRequest(context, request){
	return new Promise((resolve, reject) => {
		if(typeof request.entity === 'undefined' || typeof request.entity['@type'] === 'undefined' || typeof entityMap[request.entity['@type']] === 'undefined')
			reject(new Error('Unknown entity / Malformed request structure'));
		const url = determineRequestUrl(request);
		const useBaseUrl = (url.indexOf('/api/') !== -1);
		const method = typeof request.method !== 'undefined' ? request.method : 'get' ;
		
		const promise = (method === 'get' || method === 'delete') ? this._vm.$concreteApi[method](context.state.apiKey,url,useBaseUrl,determineRequestHeaders(request)) : this._vm.$concreteApi[method](context.state.apiKey,url,buildFormattedPayload(request),useBaseUrl,determineRequestHeaders(request)) ;
		promise.then(response => {
			if(typeof request.skipMutations === 'undefined' || request.skipMutations === false)
			{
				let mutations = [];
				if (typeof request.mutations !== 'undefined')
					mutations = mutations.concat(request.mutations);
				
				if (typeof entityMap[request.entity['@type']].defaultMutations !== 'undefined')
					mutations = mutations.concat(entityMap[request.entity['@type']].defaultMutations);
				
				for (let i = 0; i < mutations.length; i++)
				{
					if (typeof mutations[i].methods !== 'undefined' && mutations[i].methods.indexOf(method) === -1)
						continue;
					
					let payload = null;
					if (mutations[i].parameter === 'response')
						payload = response;
					
					context.commit(mutations[i].mutation, payload)
				}
			}
			resolve(response);
		})
		.catch(error => {
			reject(error)
		});
	});
}


function determineRequestHeaders(request)
{
	const headers = {};

	if(typeof request.format !== 'undefined' && request.format === 'formData')
		headers['Content-Type'] = 'multipart/form-data';

	return headers;
}

function buildFormattedPayload(request)
{
	if(typeof request.format === 'undefined' || request.format === 'json')
		return request.payload;

	if(request.format === 'formData')
	{
		const formData = new FormData();
		Object.keys(request.payload).forEach((key) => {
			formData.append(key,request.payload[key]);
		});
		return formData;
	}

	return request.payload;
}

function determineRequestUrl(request)
{
	let parametersUrl = '';
	if(typeof request.parameters !== 'undefined')
	{
		parametersUrl += '?';
		Object.keys(request.parameters).forEach((property) => {
			if(Array.isArray(request.parameters[property]))
			{
				request.parameters[property].forEach((value) => {
					parametersUrl += property+'[]='+value+'&';
				})
			}
			else
			{
				parametersUrl += property+'='+request.parameters[property]+'&';
			}
		});
		parametersUrl = parametersUrl.substring(0, parametersUrl.length - 1);
	}
	if(typeof request.entity['@id'] !== 'undefined')
	{
		return request.entity['@id']+parametersUrl;
	}
	else
	{
		let url = '/'+entityMap[request.entity['@type']].endpoint;
		if(typeof request.entity['id'] !== 'undefined')
			url += '/'+request.entity['id'];
		return url+parametersUrl;
	}
}


export function startAssignment(context, payload){
	return new Promise((resolve, reject) => {
		this._vm.$concreteApi.post(context.state.apiKey,'/hauling-assignment/change-state/start',payload,true)
		.then(result => {
			if(typeof result.status !== 'undefined' && result.status === true)
			{
				context.commit('updateHaulingAssignmentStatus',{status:2, id: extractIdFromIri(payload.haulingAssignment)})
				resolve(true);
			}
			else
			{
				reject(false)
			}
		})
		.catch(error => {
			reject(error);
		})
	});
}


export function endAssignment(context, payload){
	return new Promise((resolve, reject) => {
		this._vm.$concreteApi.post(context.state.apiKey,'/hauling-assignment/change-state/end',payload,true)
		.then(result => {
			if(typeof result.status !== 'undefined' && result.status === true)
			{
				context.commit('updateHaulingAssignmentStatus',{status:3, id: extractIdFromIri(payload.haulingAssignment)})
				resolve(true);
			}
			else
			{
				reject(false)
			}
		})
		.catch(error => {
			reject(error);
		})
	});
}
