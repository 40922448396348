import axios from 'axios';

class ConcreteApi {
	install (vue) {
		vue.prototype.$concreteApi = this;
        this.urlBase = (typeof process !== 'undefined' && typeof process.env !== 'undefined' && typeof process.env.VUE_APP_CONCRETE_API_URL !== 'undefined') ? process.env.VUE_APP_CONCRETE_API_URL : 'http://concrete-elephant-api.mtm';
        this.apiBase = this.urlBase+'/api';
    }
    buildRequestObject(method, token, route, data, useBaseUrl, headers){
	    const url = (useBaseUrl !== 'undefined' && useBaseUrl === true) ? this.urlBase + route : this.apiBase + route ;
	    let request = { method: method, url: url, headers: {'Content-Type': 'application/json'} };
	    if (typeof token !== 'undefined' && token !== false)
	    {
		    request.headers = {'Content-Type': 'application/json', 'X-AUTH-TOKEN': token};
	    }
	    if(typeof headers === 'object')
	    {
		    for(let attribute in headers) {request.headers[attribute] = headers[attribute];}
	    }
	    if(typeof data !== 'undefined' && data !== false)
	    {
	    	request.data = data;
	    }
	    
	    return request;
    }
    get (token, route, useBaseUrl, headers) {
        return new Promise((resolve, reject) => {
            axios(this.buildRequestObject('GET',token, route, false, useBaseUrl, headers))
            .then(response => {
                if (typeof response.data !== 'undefined' && typeof response.status !== 'undefined' && (response.status === 200 || response.status === 202)) {
                    resolve(response.data)
                } else {
                    reject('unknown')
                }
            }).catch(error => {
                reject(error.response)
            })
        })
    }
    post (token, route, data, useBaseUrl, headers) {
        return new Promise((resolve, reject) => {
            
            axios(this.buildRequestObject('POST',token, route, data, useBaseUrl, headers)).then(response => {
                if (typeof response.status !== 'undefined' && (response.status === 201 || response.status === 200) && typeof response.data !== 'undefined') {
                    resolve(response.data)
                } else if(typeof response.status !== 'undefined' && response.status === 202){
                    resolve(202)
                } else {
                    reject(new Error('An error occured, please try again'))
                }
            }).catch(error => {
                reject(error.response)
            })
        })
    }
    put (token, route, data, useBaseUrl, headers) {
        return new Promise((resolve, reject) => {
            axios(this.buildRequestObject('PUT',token, route, data, useBaseUrl, headers))
            .then(response => {
                if (typeof response.status !== 'undefined' && (response.status === 200 || response.status === 202) && typeof response.data !== 'undefined') {
                    resolve(response.data)
                } else {
                    reject(new Error('An error occured, please try again'))
                }
            }).catch(error => {
                reject(error.response)
            })
        })
    }
    delete (token, route, useBaseUrl, headers) {
        return new Promise((resolve, reject) => {
            axios(this.buildRequestObject('DELETE',token, route, false, useBaseUrl, headers))
            .then(response => {
                if (typeof response.status !== 'undefined' && response.status === 204) {
                    resolve(response)
                } else {
                    reject(new Error('An error occured, please try again'))
                }
            }).catch(error => {
                reject(error.response)
            })
        })
    }
}

const concreteApi = new ConcreteApi();
export default concreteApi;

